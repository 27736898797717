import React from 'react';
import { MdOutlineCancel, MdOutlineCheck } from 'react-icons/md';

import {
  Button,
  ButtonGroup,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';

import { useModal } from './hook';
import { ConfirmModalSettings } from './types';

const defaultLabels = {
  cancel: 'Cancelar',
  confirm: 'Confirmar',
};

export function ConfirmModal({
  id,
  title,
  innerProps,
  label,
  onCancel,
  onConfirm,
  closeOnCancel = true,
  closeOnConfirm = true,
  children,
}: ConfirmModalSettings) {
  const modals = useModal();
  const onConfirmHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (typeof innerProps?.confirmButton?.onClick === 'function') {
      innerProps.confirmButton.onClick(event);
    }
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
    if (closeOnConfirm) {
      modals.close(id as string);
    }
  };

  const onCancelHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (typeof innerProps?.cancelButton?.onClick === 'function') {
      innerProps.cancelButton.onClick(event);
    }
    if (typeof onCancel === 'function') {
      onCancel();
    }
    if (closeOnCancel) {
      modals.close(id as string);
    }
  };

  return (
    <>
      <ModalHeader {...innerProps?.header}>{title}</ModalHeader>
      <ModalCloseButton {...innerProps?.closeButton} />
      <ModalBody {...innerProps?.body}>{children}</ModalBody>
      <ModalFooter {...innerProps?.footer}>
        <ButtonGroup>
          <Button
            colorScheme="red"
            leftIcon={<Icon as={MdOutlineCancel} />}
            {...innerProps?.cancelButton}
            onClick={onCancelHandler}
          >
            {label?.cancel || defaultLabels.cancel}
          </Button>
          <Button
            leftIcon={<Icon as={MdOutlineCheck} />}
            {...innerProps?.confirmButton}
            onClick={onConfirmHandler}
          >
            {label?.confirm || defaultLabels.confirm}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
}

export default ConfirmModal;
