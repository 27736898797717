import React, {
  ComponentPropsWithRef,
  ElementRef,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
} from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import { AppShellMain } from './app-shell-main';
import { AppShellProvider } from './app-shell-provider';
import { AppShellSection } from './app-shell-section';
import { AppShellSidebar } from './app-shell-sidebar';

function AppShellBase(
  { children, ...props }: BoxProps,
  ref: ForwardedRef<ElementRef<typeof Box>>,
) {
  return (
    <AppShellProvider>
      <Box ref={ref} {...props}>
        {children}
      </Box>
    </AppShellProvider>
  );
}

type IAppShell = ForwardRefExoticComponent<
  ComponentPropsWithRef<typeof AppShellBase>
> & {
  Main: typeof AppShellMain;
  Section: typeof AppShellSection;
  Sidebar: typeof AppShellSidebar;
};

export const AppShell = forwardRef(AppShellBase) as IAppShell;

AppShell.displayName = '@cinesj/web-ui/components/AppShell';
AppShell.Main = AppShellMain;
AppShell.Section = AppShellSection;
AppShell.Sidebar = AppShellSidebar;
