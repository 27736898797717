import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { isDesktop } from 'react-device-detect';
import { RemoveScroll } from 'react-remove-scroll';
import { animated, useSpring } from 'react-spring';

import { Box, useColorModeValue } from '@chakra-ui/react';
import { useGesture } from '@use-gesture/react';
import { useHover } from 'usehooks-ts';

import { CanvasControls, CanvasControlsProps } from './controls';
import { CanvasDimensions } from './types';

export type CanvasProps = {
  children: ReactNode;
  dimensions: CanvasDimensions;
  controlsProps?: Omit<CanvasControlsProps, 'methods'>;
  disableScroll?: boolean;
  backgroundColor?: {
    light: string;
    dark: string;
  };
};

const defaultControlProps: Omit<CanvasControlsProps, 'methods'> = {
  controlsPosition: 'top-right',
  spacing: '4',
};

const defaultBackgroundColor: CanvasProps['backgroundColor'] = {
  light: 'gray.900',
  dark: 'gray.900',
};

export function Canvas({
  dimensions,
  controlsProps = defaultControlProps,
  disableScroll = false,
  children,
  backgroundColor = defaultBackgroundColor,
}: CanvasProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);

  const isHoverOnWrapper = useHover(wrapperRef);

  const bgColor = useColorModeValue(
    backgroundColor?.light,
    backgroundColor?.dark,
  );

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
  }));

  const setScaleMethod = useCallback(
    (scale: number, immediate?: boolean) => {
      if (scale > 0.1 && scale <= 2) {
        api.start({ scale, immediate });
      }
    },
    [api],
  );

  const addScaleMethod = useCallback(() => {
    setScaleMethod(style.scale.get() + 0.1);
  }, [setScaleMethod, api]);

  const subScaleMethod = useCallback(() => {
    setScaleMethod(style.scale.get() - 0.1);
  }, [setScaleMethod, api]);

  const setPositionMethod = useCallback(
    (x: number, y: number, immediate?: boolean) => {
      api.start({ x, y, immediate });
    },
    [api],
  );

  const fitViewMethod = useCallback(() => {
    const box = boxRef.current;
    const drawer = drawerRef.current;
    if (box && drawer) {
      const boxWidth = box.clientWidth;
      const boxHeight = box.clientHeight;
      const drawerWidth = drawer.clientWidth;
      const drawerHeight = drawer.clientHeight;
      const scale =
        Math.min(boxWidth / drawerWidth, boxHeight / drawerHeight) || 1;
      setScaleMethod(scale);
      setPositionMethod(0, 0);
    }
  }, [setScaleMethod, setPositionMethod, api]);

  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y] }) => {
        if (pinching) {
          return cancel();
        }
        return api.start({ x, y, immediate: true });
      },
      onWheel: ({ direction: [, dirY] }) => {
        if (dirY && boxRef.current && drawerRef.current) {
          const currentScale = style.scale.get();
          const newScale = style.scale.get() + dirY * -0.1;

          const boxWidth = boxRef.current.clientWidth;
          const boxHeight = boxRef.current.clientHeight;

          const drawerWidth = drawerRef.current.clientWidth;
          const drawerHeight = drawerRef.current.clientHeight;

          const newX = style.x.get() - (boxWidth - drawerWidth) * dirY * -0.1;
          const newY = style.y.get() - (boxHeight - drawerHeight) * dirY * -0.1;

          if (newScale > 0.1 && newScale < 2) {
            return api.start({
              scale: newScale,
              x: newX,
              y: newY,
            });
          }
          return api.start({ scale: currentScale });
        }
        return null;
      },
      onPinch: ({
        origin: [ox, oy],
        first,
        movement: [ms],
        offset: [s],
        memo,
      }) => {
        if (boxRef.current) {
          if (first) {
            const { width, height, x, y } =
              boxRef.current.getBoundingClientRect();
            const tx = ox - (x + width / 2);
            const ty = oy - (y + height / 2);
            const newMemo = [style.x.get(), style.y.get(), tx, ty];
            return newMemo;
          }

          const x = memo[0] - (ms - 1) * memo[2];
          const y = memo[1] - (ms - 1) * memo[3];
          api.start({ scale: s, x, y, immediate: true });
          return memo;
        }
        return memo;
      },
    },
    {
      target: boxRef,
      drag: { from: () => [style.x.get(), style.y.get()] },
      wheel: { from: () => [style.x.get(), style.y.get()] },
      pinch: { scaleBounds: { min: 0.1, max: 2 }, rubberband: true },
    },
  );

  useEffect(() => {
    const handler = (e: any) => e.preventDefault();
    document.addEventListener('gesturestart', handler);
    document.addEventListener('gesturechange', handler);
    document.addEventListener('gestureend', handler);
    return () => {
      document.removeEventListener('gesturestart', handler);
      document.removeEventListener('gesturechange', handler);
      document.removeEventListener('gestureend', handler);
    };
  }, []);

  return (
    <RemoveScroll
      ref={wrapperRef}
      forwardProps
      enabled={isDesktop && !disableScroll && isHoverOnWrapper}
    >
      <Box
        w={dimensions.width}
        h={dimensions.height}
        position="relative"
        overflow="hidden"
        bg={bgColor}
      >
        <CanvasControls
          {...defaultControlProps}
          {...controlsProps}
          methods={{
            addScale: addScaleMethod,
            subScale: subScaleMethod,
            fitView: fitViewMethod,
          }}
        />
        <Box
          ref={boxRef}
          w="full"
          h="full"
          overflow="hidden"
          sx={{
            touchAction: 'none',
          }}
        >
          <animated.div
            ref={drawerRef}
            style={{
              x: style.x,
              y: style.y,
              scale: style.scale,
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            {children}
          </animated.div>
        </Box>
      </Box>
    </RemoveScroll>
  );
}
