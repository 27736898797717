import './scroll-area.module.css';
import React, {
  ComponentPropsWithRef,
  ElementRef,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';

import { cn } from '@cinesj/web-ui/utils/cn';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

export interface ScrollAreaProps
  extends ComponentPropsWithRef<typeof ScrollAreaPrimitive.Viewport> {
  children: ReactNode;
  componentsProps?: {
    Root: ComponentPropsWithRef<typeof ScrollAreaPrimitive.Root>;
    Scrollbar: ComponentPropsWithRef<typeof ScrollAreaPrimitive.Scrollbar>;
    Thumb: ComponentPropsWithRef<typeof ScrollAreaPrimitive.Thumb>;
    Corner: ComponentPropsWithRef<typeof ScrollAreaPrimitive.Corner>;
  };
}

function ScrollAreaBase(
  { children, componentsProps, ...props }: ScrollAreaProps,
  ref: ForwardedRef<ElementRef<typeof ScrollAreaPrimitive.Viewport>>,
) {
  return (
    <ScrollAreaPrimitive.Root
      {...componentsProps?.Root}
      className={cn('ScrollAreaRoot', componentsProps?.Root?.className)}
    >
      <ScrollAreaPrimitive.Viewport
        ref={ref}
        {...props}
        className={cn('ScrollAreaViewport', props.className)}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollAreaPrimitive.Scrollbar
        orientation="horizontal"
        {...componentsProps?.Scrollbar}
        className={cn(
          'ScrollAreaScrollbar',
          componentsProps?.Scrollbar?.className,
        )}
      >
        <ScrollAreaPrimitive.Thumb
          {...componentsProps?.Thumb}
          className={cn('ScrollAreaThumb', componentsProps?.Thumb?.className)}
        />
      </ScrollAreaPrimitive.Scrollbar>
      <ScrollAreaPrimitive.Scrollbar
        orientation="vertical"
        {...componentsProps?.Scrollbar}
        className={cn(
          'ScrollAreaScrollbar',
          componentsProps?.Scrollbar?.className,
        )}
      >
        <ScrollAreaPrimitive.Thumb
          {...componentsProps?.Thumb}
          className={cn('ScrollAreaThumb', componentsProps?.Thumb?.className)}
        />
      </ScrollAreaPrimitive.Scrollbar>
      <ScrollAreaPrimitive.Corner
        {...componentsProps?.Corner}
        className={cn('ScrollAreaCorner', componentsProps?.Corner?.className)}
      />
    </ScrollAreaPrimitive.Root>
  );
}

export const ScrollArea = forwardRef(ScrollAreaBase);
ScrollArea.displayName = '@cinesj/web-ui/components/ScrollArea';
