import React from 'react';
import { MdOutlineClose, MdOutlineSave, MdOutlineUpload } from 'react-icons/md';

import {
  Button,
  ButtonGroup,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  SimpleGrid,
} from '@chakra-ui/react';
import { useControlledState } from '@cinesj/hooks';

import { MotionAspectRatio } from '../motion/aspect-ratio';

export type Image = {
  id: string;
  url: string;
};

export type ModalGridProps = {
  selected: string;
  images: Image[];
  openUploadModal: () => void;
  onClose: () => void;
  onSelect: (imageId: string) => void;
};

export function ModalGrid({
  images,
  selected: propsSelected,
  onSelect: propsOnSelect,
  onClose,
  openUploadModal,
}: ModalGridProps) {
  const [selected, setSelect] = useControlledState({
    value: propsSelected,
  });

  const onSelectMethod = (imageId: string) => {
    const imageIsSelected = selected === imageId;
    if (imageIsSelected) {
      setSelect('');
    } else {
      setSelect(imageId);
    }
  };

  const onSubmitSelectionMethod = () => {
    propsOnSelect(selected);
    onClose();
  };

  return (
    <>
      <ModalHeader>Escolha uma imagem</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <SimpleGrid
          columns={{
            base: 2,
            sm: 3,
            md: 4,
            lg: 6,
          }}
          gap="2"
        >
          {images.map(image => {
            const isSelected = image.id === selected;

            return (
              <MotionAspectRatio
                key={image.id}
                ratio={1}
                onClick={() => {
                  onSelectMethod(image.id);
                }}
                border="solid"
                borderColor="brand.500"
                variants={{
                  default: {
                    borderWidth: '0px',
                  },
                  open: {
                    borderWidth: '4px',
                  },
                }}
                userSelect="none"
                cursor="pointer"
                initial="default"
                animate={isSelected ? 'open' : 'default'}
                transition={{
                  ease: 'linear',
                }}
              >
                <img src={image.url} alt={image.id} draggable="false" />
              </MotionAspectRatio>
            );
          })}
        </SimpleGrid>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            colorScheme="blue"
            onClick={openUploadModal}
            leftIcon={<Icon as={MdOutlineUpload} />}
          >
            Upload
          </Button>
          <Button
            colorScheme="red"
            leftIcon={<Icon as={MdOutlineClose} />}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            leftIcon={<Icon as={MdOutlineSave} />}
            isDisabled={selected === ''}
            onClick={onSubmitSelectionMethod}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
}

export default ModalGrid;
