import React from 'react';
import { MdOutlineSave } from 'react-icons/md';

import { Button, Icon } from '@chakra-ui/react';

import type { SaveButtonProps } from './types';

/**
 * Save button, a button with a save icon and a loading state commonly used in forms to submit data
 * @param children - The button label
 * @param type - The button type
 * @param isLoading - The loading state
 * @param onClick - The button click handler
 * @constructor
 */
export function SaveButton({
  children = 'Salvar',
  type = 'submit',
  isLoading,
  onClick,
}: SaveButtonProps) {
  return (
    <Button
      type={type}
      onClick={onClick}
      isLoading={isLoading}
      leftIcon={<Icon as={MdOutlineSave} />}
    >
      {children}
    </Button>
  );
}
