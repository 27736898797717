import React from 'react';

import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';

export interface CardProps extends BoxProps {
  children?: React.ReactNode;
  variant?: string;
}

function CardCard(
  { children, variant, ...rest }: CardProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const styles = useStyleConfig('card', { variant });

  return (
    <Box ref={ref} __css={styles} {...rest}>
      {children}
    </Box>
  );
}

export const Card = React.forwardRef<HTMLDivElement, CardProps>(CardCard);
