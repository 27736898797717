import React, { ForwardedRef, forwardRef, useEffect } from 'react';
import { Control, useController } from 'react-hook-form';
import { useIMask } from 'react-imask';

import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  useMergeRefs,
} from '@chakra-ui/react';
import { useID } from '@cinesj/web-ui/hooks/use-id';
import { FactoryOpts } from 'imask';

export type InputMaskProps = {
  name: string;
  error?: string;
  label?: string;
  helperText?: string;

  maskOptions: FactoryOpts;

  control?: Control<any, any>;
} & ChakraInputProps;

function InputMaskBase(
  {
    control: propsControl,
    error: propsError,
    maskOptions,
    id,
    name,
    label: propsLabel,
    helperText,
    isRequired,
    isReadOnly,
    isDisabled,
    ...props
  }: InputMaskProps,
  inputRef: ForwardedRef<HTMLInputElement>,
) {
  const controller = useController({
    name,
    control: propsControl,
  });
  const inputId = useID('input-mask', id);
  const imask = useIMask(maskOptions);

  const mergedRef = useMergeRefs(
    inputRef,
    imask.ref as any,
    controller.field.ref,
  );

  const label = propsLabel || controller.field.name || name;

  const error = propsError || controller.fieldState.error;

  const mergedProps = {
    id: inputId,
    ...props,
    ...{
      name: controller.field.name,
      onBlur: controller.field.onBlur,
      onChange: controller.field.onChange,
    },
  };

  useEffect(() => {
    if (typeof controller.field.value === 'string') {
      imask.setUnmaskedValue(controller.field.value);
    }
  }, [controller.field.value]);

  return (
    <FormControl
      isDisabled={isDisabled}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isInvalid={!!error || props.isInvalid}
      id={inputId}
    >
      <FormLabel>{label}</FormLabel>
      <ChakraInput ref={mergedRef} {...mergedProps} />
      {error ? (
        <FormErrorMessage>
          {typeof error === 'string' ? error : error.message}
        </FormErrorMessage>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export const InputMask = forwardRef(InputMaskBase);
