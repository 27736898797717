import { OmniRouter } from './omni-router';

export function useOmniRouter() {
  const router = OmniRouter.useRouter();

  return {
    back() {
      router.back();
    },
    forward() {
      router.forward();
    },
    refresh() {
      router.refresh();
    },
    push(href: string) {
      router.push(href);
    },
    replace(href: string) {
      router.replace(href);
    },
  };
}
