import React, { ForwardedRef, forwardRef, ReactNode } from 'react';

import { OmniRouter } from './omni-router';

export type OmniRouterLinkProps = {
  href: string;
  className?: string;
  children: ReactNode;
};

function OmniRouterLinkBase(
  { children, ...props }: OmniRouterLinkProps,
  ref: ForwardedRef<any>,
) {
  return (
    <OmniRouter.Link ref={ref} {...props}>
      {children}
    </OmniRouter.Link>
  );
}

export const OmniRouterLink = forwardRef(OmniRouterLinkBase);
