import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  MdOutlineClose,
  MdOutlineDriveFolderUpload,
  MdOutlineUpload,
} from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';

import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  List,
  ListItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { filesRules, FileTypes } from '@cinesj/constants';
import { useAsyncMutation } from '@cinesj/hooks';

type OnSubmitUploadProps = {
  file: File;
  fileType: FileTypes;
};

export type ModalUploadProps = {
  closeUploadModal: () => void;
  onSubmitUpload: (props: OnSubmitUploadProps) => Promise<void>;
};

export function ModalUpload({
  closeUploadModal,
  onSubmitUpload,
}: ModalUploadProps) {
  const [previewFile, setPreviewFile] = useState<File>();
  const [selectedType, setSelectedType] = useState<FileTypes>('poster');

  const toast = useToast();

  const submitUploadMutation = useAsyncMutation(onSubmitUpload, {
    onSuccess: () => {
      toast({
        title: 'Sucesso!',
        description: 'Arquivo enviado com sucesso!',
        status: 'success',
        duration: 5000,
      });
      closeUploadModal();
    },
  });

  const onDropHandler = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      toast({
        title: 'Ops!',
        description: 'Nenhum arquivo foi enviado',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (acceptedFiles.length > 1) {
      toast({
        title: 'Ops!',
        description:
          'Desculpe, por enquanto é possível enviar só um arquivo por vez',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      const file = acceptedFiles[0];

      if (file) {
        if (
          file.type.startsWith('image') &&
          filesRules.poster.allowedExtensions.includes(file.type.split('/')[1])
        ) {
          setPreviewFile(file);
        } else {
          toast({
            title: 'Ops!',
            description: 'Formato de arquivo não permitido',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
  }, []);

  const onSubmitHandler = async () => {
    if (previewFile) {
      await submitUploadMutation.mutate({
        file: previewFile,
        fileType: selectedType,
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropHandler,
  });

  return (
    <>
      <ModalHeader>Upload</ModalHeader>
      <ModalBody>
        {previewFile ? (
          <Grid
            gap="4"
            gridTemplateColumns={`${Math.round(
              filesRules.poster.width / 1.5,
            )}px 1fr`}
            gridTemplateRows={`${Math.round(
              filesRules.poster.height / 1.5,
            )}px 1fr`}
          >
            <AspectRatio>
              <Image
                src={URL.createObjectURL(previewFile)}
                alt={previewFile.name}
              />
            </AspectRatio>
            <Box>
              <VStack align="start" spacing={2}>
                <HStack w="full" alignItems="center">
                  <Heading as="h2" size="md">
                    Informações
                  </Heading>
                  <Spacer />
                  <IconButton
                    colorScheme="red"
                    aria-label="limpa o preview do upload"
                    icon={<Icon as={RiDeleteBin2Line} />}
                    onClick={() => setPreviewFile(undefined)}
                  />
                </HStack>
                <Divider borderColor="black" size="4" />
                <List>
                  <ListItem>
                    <Text maxW="250">
                      <strong>Nome:</strong> {previewFile.name}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      <strong>Tamanho:</strong>{' '}
                      {(previewFile.size / 1024 / 1024).toFixed(2)} MB
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      <strong>Tipo:</strong> {previewFile.type}
                    </Text>
                  </ListItem>
                </List>
                <Divider borderColor="black" />
                <FormControl>
                  <FormLabel>
                    <strong>Categoria do arquivo</strong>
                  </FormLabel>
                  <Select
                    value={selectedType}
                    onChange={event =>
                      setSelectedType(event.target.value as FileTypes)
                    }
                  >
                    <option value="poster">Poster</option>
                    <option value="banner">Banner</option>
                    <option value="other">Outro</option>
                  </Select>
                </FormControl>
              </VStack>
            </Box>
          </Grid>
        ) : (
          <Center
            p={6}
            bg={
              isDragActive ? 'rgba(6, 86, 102, 0.20)' : 'rgba(0, 103, 70, 0.20)'
            }
            border="1px"
            borderColor="cyan.500"
            borderStyle="dashed"
            rounded="sm"
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            <VStack>
              <Icon as={MdOutlineDriveFolderUpload} boxSize="14" />
              <Text>
                Arrate o arquivo para aqui ou clique aqui para escolher o
                arquivo
              </Text>
            </VStack>
          </Center>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            colorScheme="red"
            leftIcon={<Icon as={MdOutlineClose} />}
            onClick={closeUploadModal}
            isDisabled={submitUploadMutation.isLoading}
          >
            Cancelar
          </Button>
          <Button
            isDisabled={!previewFile}
            isLoading={submitUploadMutation.isLoading}
            leftIcon={<Icon as={MdOutlineUpload} />}
            onClick={onSubmitHandler}
          >
            Enviar
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
}

export default ModalUpload;
