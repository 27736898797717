import React, { ForwardedRef, forwardRef } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  useMergeRefs,
} from '@chakra-ui/react';
import { useID } from '@cinesj/web-ui/hooks/use-id';

export type InputProps = {
  label?: string;
  helperText?: string;
  register?: UseFormRegisterReturn;
  error?: FieldError | string;
} & ChakraInputProps;

function InputBase(
  {
    register,
    error,
    id,
    label: propsLabel,
    helperText,
    isDisabled,
    isInvalid: propsIsInvalid,
    isReadOnly,
    isRequired,
    ...props
  }: InputProps,
  inputRef: ForwardedRef<HTMLInputElement>,
) {
  const inputId = useID('input', id);

  const label = propsLabel || register?.name || props.name;

  const isInvalid = !!error || propsIsInvalid;

  const mergedRef = useMergeRefs(inputRef, register?.ref);

  const mergedProps = {
    id: inputId,
    ref: mergedRef,
    ...props,
    ...register,
  };

  return (
    <FormControl
      isDisabled={isDisabled}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isInvalid={isInvalid}
      id={inputId}
    >
      <FormLabel>{label}</FormLabel>
      <ChakraInput {...mergedProps} />
      {error ? (
        <FormErrorMessage>
          {typeof error === 'string' ? error : error.message}
        </FormErrorMessage>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export const Input = forwardRef(InputBase);
