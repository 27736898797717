export function parseUrlQueryParams(url: string): { [key: string]: string } {
  const params: { [key: string]: string } = {};
  const urlParts = url.split('?');
  if (urlParts.length > 1) {
    const queryParts = urlParts[1].split('&');
    queryParts.forEach(part => {
      const [key, value] = part.split('=');
      params[key] = value;
    });
  }
  return params;
}
