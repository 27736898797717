import React from 'react';
import { MdArrowBackIos } from 'react-icons/md';

import { Button, Icon, IconButton } from '@chakra-ui/react';
import { useMediaQuery } from '@cinesj/web-ui/hooks/use-media-query';
import {
  useOmniSearchParams,
  OmniRouterLink,
} from '@cinesj/web-ui/omni-router';

export type ReturnButtonProps = {
  href: string;
  showOnlyWhenReturnUrl?: boolean;
};

export function ReturnButton({
  href,
  showOnlyWhenReturnUrl = false,
}: ReturnButtonProps) {
  const mediaQuery = useMediaQuery();
  const searchParams = useOmniSearchParams();

  const returnUrl = searchParams.get('returnUrl');

  const goUrl = returnUrl || href;

  if (showOnlyWhenReturnUrl && !returnUrl) {
    return null;
  }

  if (!mediaQuery.sm) {
    return (
      <IconButton
        as={OmniRouterLink}
        href={goUrl}
        icon={<Icon as={MdArrowBackIos} />}
        aria-label="Voltar"
      />
    );
  }

  return (
    <Button
      as={OmniRouterLink}
      href={goUrl}
      leftIcon={<Icon as={MdArrowBackIos} />}
    >
      Voltar
    </Button>
  );
}
