/**
 * Allow to use async functions in map
 * @param oldArray array will be remapped
 * @param callback function that will be called for each item in the array
 * @returns new array remapped with the callback
 */
export async function asyncMap<T, P>(
  oldArray: T[],
  callback: (item: T, index: number, array: T[]) => Promise<P>,
): Promise<P[]> {
  return Promise.all(oldArray.map(callback));
}
