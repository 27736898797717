import { useCallback, useMemo, useState } from 'react';

import { useMediaQuery } from './use-media-query';

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter(page => page > 0);
}

export type Paginator = {
  currentPage: number;
  lastPage: number;
  siblingPagesCount: number;
  pages: number[];
  setCurrentPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
};

export type PaginatorResponsiveSiblingPagesCount = {
  base: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

export type UsePaginatorProps = {
  initialPage?: number;
  totalNumberOfItems?: number;
  itemsPerPage?: number;
  siblingPagesCount?: PaginatorResponsiveSiblingPagesCount;
};

const defaultSiblingPagesCount: PaginatorResponsiveSiblingPagesCount = {
  base: 1,
  sm: 1,
  md: 1,
  lg: 2,
};

export function usePaginator({
  initialPage = 1,
  totalNumberOfItems = Infinity,
  itemsPerPage = 10,
  siblingPagesCount = defaultSiblingPagesCount,
}: UsePaginatorProps): Paginator {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const mediaQuery = useMediaQuery();

  const responsiveSiblingPagesCount = useMemo(() => {
    if (mediaQuery.xl && siblingPagesCount.xl) {
      return siblingPagesCount.xl;
    }
    if (mediaQuery.lg && siblingPagesCount.lg) {
      return siblingPagesCount.lg;
    }
    if (mediaQuery.md && siblingPagesCount.md) {
      return siblingPagesCount.md;
    }
    if (mediaQuery.sm && siblingPagesCount.sm) {
      return siblingPagesCount.sm;
    }
    return siblingPagesCount.base;
  }, [siblingPagesCount, mediaQuery]);

  const lastPage = useMemo(
    () => Math.ceil(totalNumberOfItems / itemsPerPage),
    [],
  );

  const pages = useMemo<Paginator['pages']>(() => {
    const previousPages =
      currentPage > 1
        ? generatePagesArray(
            currentPage - 1 - responsiveSiblingPagesCount,
            currentPage - 1,
          )
        : [];

    const nextPages =
      currentPage < lastPage
        ? generatePagesArray(
            currentPage,
            Math.min(currentPage + responsiveSiblingPagesCount, lastPage),
          )
        : [];

    const newPages: Paginator['pages'] = [
      ...previousPages,
      currentPage,
      ...nextPages,
    ];
    return newPages;
  }, [currentPage, lastPage, responsiveSiblingPagesCount]);

  const setCurrentPageMethod = useCallback((page: number) => {
    if (page < 1 || page > lastPage) {
      return;
    }
    setCurrentPage(page);
  }, []);

  const nextPageMethod = useCallback(() => {
    const nextPage = currentPage + 1;
    if (nextPage <= lastPage) {
      setCurrentPage(nextPage);
    }
  }, [currentPage, lastPage]);

  const previousPageMethod = useCallback(() => {
    const previousPage = currentPage - 1;
    if (previousPage > 0) {
      setCurrentPage(previousPage);
    }
  }, [currentPage]);

  return {
    currentPage,
    nextPage: nextPageMethod,
    pages,
    siblingPagesCount: responsiveSiblingPagesCount,
    lastPage,
    previousPage: previousPageMethod,
    setCurrentPage: setCurrentPageMethod,
  };
}
