import { useEffect, useState } from 'react';

import { useTheme } from '@chakra-ui/react';

export interface MediaQuerys {
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  '2xl': boolean;
}

export const useMediaQuery = () => {
  const theme = useTheme();

  const [media, setMedia] = useState<MediaQuerys>({
    xl: true,
    sm: true,
    md: true,
    lg: true,
    '2xl': true,
  });

  useEffect(() => {
    const handler = () => {
      const width = window.innerWidth;
      const newMedia: MediaQuerys = {
        sm: width >= theme.breakpoints.sm.replace('px', ''),
        md: width >= theme.breakpoints.md.replace('px', ''),
        lg: width >= theme.breakpoints.lg.replace('px', ''),
        xl: width >= theme.breakpoints.xl.replace('px', ''),
        '2xl': width >= theme.breakpoints['2xl'],
      };

      setMedia(newMedia);
    };

    handler();

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return media;
};
