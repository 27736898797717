import {
  extendTheme,
  ThemeConfig,
  withDefaultColorScheme,
} from '@chakra-ui/react';

import { colors } from './colors';
import { components } from './components';

const breakpoints = {
  xs: '320px',
  sm: '425px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
  '2xl': '1536px',
};

export const cinesjTheme: any = extendTheme(
  {
    initialColorMode: 'light',
    useSystemColorMode: true,
    colors,
    breakpoints,
    components,
  } as ThemeConfig,
  withDefaultColorScheme({ colorScheme: 'green' }),
);
