import React, { ElementType, ReactNode } from 'react';

import { Button, Heading, HStack, Icon, VStack } from '@chakra-ui/react';
import { useIsDarkMode } from '@cinesj/web-ui/hooks';
import { OmniRouterLink } from 'lib/omni-router';

export type NavLinkProps = {
  label: string;
  href?: string;
  icon?: ElementType;
  children?: ReactNode;
};

export function NavLink({ label, href, icon, children }: NavLinkProps) {
  const isDarkMode = useIsDarkMode();

  if (children) {
    return (
      <VStack w="full" spacing={0} alignItems="start">
        <HStack w="full" p={2} color={isDarkMode ? 'gray.200' : 'gray.700'}>
          {icon && <Icon as={icon} w={6} h={6} />}
          <Heading as="p" w="full" size="sm">
            {label}
          </Heading>
        </HStack>
        <VStack w="full" pl={4}>
          {children}
        </VStack>
      </VStack>
    );
  }

  return (
    <Button
      as={OmniRouterLink}
      w="full"
      p={2}
      size="sm"
      href={href}
      variant="ghost"
      borderRadius={0}
      justifyContent="start"
      color={isDarkMode ? 'gray.200' : 'gray.700'}
      leftIcon={icon ? <Icon as={icon} w={6} h={6} /> : undefined}
    >
      {label}
    </Button>
  );
}

NavLink.displayName = '@cinesj/web-ui/components/nav-link';
