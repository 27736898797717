import React from 'react';

import {
  Modal,
  ModalContent,
  ModalOverlay,
  useBoolean,
} from '@chakra-ui/react';

import { MotionBox } from '../motion';
import ModalGrid, { ModalGridProps } from './modal-grid';
import ModalUpload, { ModalUploadProps } from './modal-upload';

export type ImageSelectorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  modalUploadProps: Omit<ModalUploadProps, 'closeUploadModal'>;
  modalGridProps: Omit<ModalGridProps, 'openUploadModal'>;
};

export function ImageSelectorModal({
  isOpen,
  onClose,
  modalUploadProps,
  modalGridProps,
}: ImageSelectorModalProps) {
  const [isUploading, isUploadingMethods] = useBoolean();

  const openUploadModal = () => {
    isUploadingMethods.on();
  };

  const closeUploadModal = () => {
    isUploadingMethods.off();
  };

  const onCloseMethod = () => {
    closeUploadModal();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseMethod}
      size="6xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        {isUploading ? (
          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ModalUpload
              {...modalUploadProps}
              closeUploadModal={closeUploadModal}
            />
          </MotionBox>
        ) : (
          <ModalGrid
            {...modalGridProps}
            openUploadModal={openUploadModal}
            onClose={onCloseMethod}
          />
        )}
      </ModalContent>
    </Modal>
  );
}
