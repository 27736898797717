import React from 'react';

import { Box, Heading, HStack, Spacer, VStack } from '@chakra-ui/react';

import { PageShellSection } from './page-shell-section';
import { PageShellProps } from './types';

export function PageShell({ title, actions, children }: PageShellProps) {
  return (
    <VStack w="full">
      <HStack w="full">
        <Heading>{title}</Heading>
        <Spacer />
        <Box>{actions}</Box>
      </HStack>
      <VStack w="full">{children}</VStack>
    </VStack>
  );
}

PageShell.Section = PageShellSection;
