import { createContext, useContext } from 'react';

export type AppShellContextProps = {
  showMainSpace: boolean;
  setShowMainSpace: (show: boolean) => void;
};

export const AppShellContext = createContext<AppShellContextProps>(
  {} as AppShellContextProps,
);

export function useAppShell() {
  return useContext(AppShellContext);
}
