import React from 'react';

import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';

import { ModalSettings } from './types';

export function ContentModal({
  title,
  noCloseButton,
  innerProps,
  children,
  footer,
}: ModalSettings) {
  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      {!noCloseButton && <ModalCloseButton {...innerProps?.closeButton} />}
      <ModalBody {...innerProps?.body}>{children}</ModalBody>
      {footer && <ModalFooter {...innerProps?.footer}>{footer}</ModalFooter>}
    </>
  );
}

export default ContentModal;
