import React from 'react';

import { Box, Center, Heading, Progress, Text, VStack } from '@chakra-ui/react';

export function WorkInProgressPage() {
  return (
    <Center as="main" h="100vh">
      <VStack maxW="350px">
        <Heading as="h1" size="4xl">
          Ops!
        </Heading>

        <Box w="100%">
          <Text>Essa pagina ainda não está pronta.</Text>
          <Progress
            colorScheme="brand"
            size="lg"
            value={75}
            isAnimated
            hasStripe
          />
        </Box>
      </VStack>
    </Center>
  );
}
