import { useCallback } from 'react';

import debounce from 'lodash.debounce';

export type UseDebounceFunctionProps = {
  timeout?: number;
};

export type UseDebounceFunctionReturn<T> = (value: T) => any;

export type RunnerFunction<T> = (value: T) => any;

const DEFAULT_TIMEOUT = 200;

export function useDebounceFunction<T>(
  func: RunnerFunction<T>,
  { timeout = DEFAULT_TIMEOUT }: UseDebounceFunctionProps = {},
): UseDebounceFunctionReturn<T> {
  return useCallback(debounce(func, timeout), [timeout]);
}
