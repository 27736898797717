import { ModalState } from './types';

type State = {
  modals: ModalState[];
  current: ModalState | null;
};

type OpenAction = {
  type: 'open';
  payload: ModalState;
};

type CloseAction = {
  type: 'close';
  payload: string;
};

type CloseAllAction = {
  type: 'closeAll';
};

export function modalReducer(
  state: State,
  action: OpenAction | CloseAction | CloseAllAction,
): State {
  switch (action.type) {
    case 'open':
      return {
        current: action.payload,
        modals: [...state.modals, action.payload],
      };
    case 'close':
      return {
        current: state.modals[state.modals.length - 2] || null,
        modals: state.modals.filter(modal => modal.id !== action.payload),
      };
    case 'closeAll':
      return {
        modals: [],
        current: state.current,
      };
    default:
      return state;
  }
}
