import React from 'react';
import { MdArrowBackIos } from 'react-icons/md';

import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useOmniRouter } from '@cinesj/web-ui/omni-router';

export function NotFoundPage() {
  const router = useOmniRouter();

  return (
    <Center as="main" h="100vh">
      <VStack>
        <Heading as="h1" size="4xl">
          404
        </Heading>
        <Text>Pagina não encontrada!</Text>
        <ButtonGroup>
          <Button leftIcon={<Icon as={MdArrowBackIos} />} onClick={router.back}>
            Voltar
          </Button>
        </ButtonGroup>
      </VStack>
    </Center>
  );
}
