export type Asset = {
  path: string;
  dimensions: {
    width: number;
    height: number;
  };
};

export const assets = {
  logoLarge: {
    dimensions: {
      width: 173,
      height: 125,
    },
    path: `/assets/logo-large.png`,
  },
  logoSmall: {
    dimensions: {
      width: 74,
      height: 74,
    },
    path: `/assets/logo-small.png`,
  },
  openGraphDefaultBanner: {
    dimensions: {
      width: 1040,
      height: 400,
    },
    path: `/assets/opengraph-default-banner.jpg`,
  },
};
