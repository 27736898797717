import { OmniRouter } from './omni-router';

export function useOmniSearchParams() {
  const searchParams = OmniRouter.useSearchParams();
  return {
    get(key: string) {
      return searchParams.get(key);
    },
  };
}
