import React from 'react';
import { Control, useController } from 'react-hook-form';
import { MdOutlineImage } from 'react-icons/md';

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  useBoolean,
  useControllableState,
} from '@chakra-ui/react';
import { FileTypes } from '@cinesj/constants';

import { ImageSelectorModal } from './modal';
import { Image } from './modal-grid';

export type ImageSelectorProps = {
  name: string;
  label?: string;
  defaultValue?: string;
  helperText?: string;
  images: Image[];
  error?: string;
  control?: Control<any, any>;
  onUpload: (props: { file: File; fileType: FileTypes }) => Promise<void>;
};

function ImageSelectorBase({
  name: propsName,
  label: propsLabel,
  defaultValue: propsDefaultValue,
  helperText: propsHelperText,
  images: propsImages,
  error: propsError,
  control,
  onUpload,
}: ImageSelectorProps) {
  const [modalIsOpen, modalMethods] = useBoolean(false);

  const controller = useController({
    name: propsName,
    control,
    defaultValue: propsDefaultValue,
  });

  const [selected, setSelected] = useControllableState({
    onChange: controller.field.onChange,
    defaultValue: propsDefaultValue,
    value: controller.field.value,
  });

  const onSelectMethod = (imageId: string) => {
    setSelected(imageId);
    modalMethods.off();
  };

  const label = propsLabel || controller.field.name || propsName;
  const error = controller.fieldState.error || propsError;

  return (
    <>
      <FormControl isInvalid={!!error}>
        <FormLabel>{label}</FormLabel>
        <Button
          leftIcon={<Icon as={MdOutlineImage} />}
          onClick={modalMethods.on}
        >
          Escolha a image
        </Button>
        <input
          ref={controller.field.ref}
          name={controller.field.name}
          type="hidden"
          value={selected}
        />
        {error ? (
          <FormErrorMessage>
            {typeof error === 'string' ? error : error.message}
          </FormErrorMessage>
        ) : (
          propsHelperText && <FormHelperText>{propsHelperText}</FormHelperText>
        )}
      </FormControl>
      <ImageSelectorModal
        isOpen={modalIsOpen}
        onClose={modalMethods.off}
        modalUploadProps={{
          onSubmitUpload: onUpload,
        }}
        modalGridProps={{
          images: propsImages,
          onClose: modalMethods.off,
          onSelect: onSelectMethod,
          selected,
        }}
      />
    </>
  );
}

export const ImageSelector = ImageSelectorBase;
