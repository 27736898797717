export type ActionMutationStart = {
  type: 'mutation-start';
};

export type ActionMutationSuccess<TData = unknown> = {
  type: 'mutation-success';
  payload: TData;
};

export type ActionMutationError<TError = unknown> = {
  type: 'mutation-error';
  payload: TError;
};

export type AsyncMutationState<TData = unknown, TError = unknown> = {
  data: TData | undefined;
  error: TError | undefined;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

export type AsyncMutationAction<TData = unknown, TError = unknown> =
  | ActionMutationStart
  | ActionMutationSuccess<TData>
  | ActionMutationError<TError>;

export function AsyncMutationReducer<TDdata = unknown, TError = unknown>(
  state: AsyncMutationState<TDdata, TError>,
  action: AsyncMutationAction<TDdata, TError>,
): AsyncMutationState<TDdata, TError> {
  switch (action.type) {
    case 'mutation-start':
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case 'mutation-success':
      return {
        ...state,
        data: action.payload,
        error: undefined,
        isLoading: false,
        isSuccess: true,
        isError: false,
      };
    case 'mutation-error':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
    default:
      return state;
  }
}
