import React from 'react';
import { MdOutlineRefresh } from 'react-icons/md';

import { Icon, IconButton, IconButtonProps } from '@chakra-ui/react';

export type RefreshButtonProps = {
  'aria-label'?: string;
  icon?: IconButtonProps['icon'];
  isLoading: boolean;
  onClick: () => void;
} & Omit<IconButtonProps, 'aria-label'>;

export function RefreshButton({
  'aria-label': ariaLabel = 'Atualizar',
  icon = <Icon as={MdOutlineRefresh} />,
  isLoading,
  onClick,
  ...rest
}: RefreshButtonProps) {
  return (
    <IconButton
      aria-label={ariaLabel}
      icon={icon}
      isLoading={isLoading}
      onClick={onClick}
      {...rest}
    />
  );
}
