import React, { ReactNode, useState } from 'react';

import { useEffectOnce } from '@cinesj/hooks';

export type NoSSRProps = {
  children?: ReactNode;
  fallback?: ReactNode;
};

export function NoSSR({ children, fallback }: NoSSRProps) {
  const [isClientSide, setIsClientSide] = useState(false);

  useEffectOnce(() => {
    setIsClientSide(true);
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isClientSide ? children : fallback}</>;
}
