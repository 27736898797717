import { useCallback, useState } from 'react';

import { useEventListener } from '@cinesj/hooks/use-event-listener';
import { useIsomorphicLayoutEffect } from '@cinesj/hooks/use-isomorphic-layout-effect';

export interface Size {
  width: number;
  height: number;
}

export type UseElementSizeProps = {
  initialSize?: Partial<Size>;
};

export type UseElementSizeReturn<T> = [(node: T | null) => void, Size];

export function useElementSize<T extends HTMLElement = HTMLDivElement>(
  { initialSize } = {} as UseElementSizeProps,
): UseElementSizeReturn<T> {
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: initialSize?.width || 0,
    height: initialSize?.height || 0,
  });

  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEventListener('resize', handleSize);

  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size];
}
