import React from 'react';
import { HiOutlineTrash } from 'react-icons/hi';

import { Button, Icon } from '@chakra-ui/react';
import { OmniRouterLink } from '@cinesj/web-ui/omni-router';

import type { RemoveButtonProps } from './types';

export function RemoveButton({ href, children }: RemoveButtonProps) {
  return (
    <Button
      as={OmniRouterLink}
      href={href}
      colorScheme="red"
      leftIcon={<Icon as={HiOutlineTrash} />}
    >
      {children}
    </Button>
  );
}
