import React from 'react';

import {
  ModalContent,
  ModalOverlay,
  Modal as ModalProvider,
} from '@chakra-ui/react';

import { ModalProps } from './types';

export function Modal({ children, innerProps, ...rest }: ModalProps) {
  return (
    <ModalProvider {...rest}>
      <ModalOverlay {...innerProps?.backdrop} />
      <ModalContent {...innerProps?.content}>{children}</ModalContent>
    </ModalProvider>
  );
}

export default Modal;
