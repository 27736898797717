import React from 'react';

import { MenuItem } from '@chakra-ui/react';
import type { Row } from '@tanstack/react-table';

import { IButtonAction } from './type';

export type ButtonActionProps<T extends object = any> = {
  action: IButtonAction<T>;
  row: Row<T>;
};

export function ButtonAction({ action, row }: ButtonActionProps) {
  return (
    <MenuItem icon={action.icon} onClick={() => action.onClick(row)}>
      {action.label}
    </MenuItem>
  );
}

export default ButtonAction;
