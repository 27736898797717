import React, { useMemo } from 'react';

export type ComponentSwitchOptionProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  case: (value: any) => boolean;
  children: React.ReactNode;
};
function ComponentSwitchOption({ children }: ComponentSwitchOptionProps) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export type ComponentSwitchProps = {
  value: any;
  children: React.ReactElement<
    ComponentSwitchOptionProps,
    typeof ComponentSwitchOption
  >[];
};

export function ComponentSwitch({ value, children }: ComponentSwitchProps) {
  if (
    !Array.isArray(children) ||
    children.find(child => child.type.name !== 'ComponentSwitchOption')
  ) {
    throw new Error(
      'ComponentSwitch expects an array of ComponentSwitch.Option',
    );
  }

  const components = useMemo(
    () => children.filter(child => child.props.case(value)),
    [value, children],
  );

  return <>{components.map(child => child.props.children)}</>;
}

ComponentSwitch.Option = ComponentSwitchOption;
