export type FileTypes = 'poster' | 'banner' | 'other';

export const fileTypes: FileTypes[] = ['poster', 'banner', 'other'];

export const filesRules = {
  poster: {
    allowedExtensions: ['jpg', 'jpeg', 'png'],
    width: 296,
    height: 420,
  },
  banner: {
    allowedExtensions: ['jpg', 'jpeg', 'png'],
    width: 1920,
    height: 490,
  },
  other: {
    allowedExtensions: ['jpg', 'jpeg', 'png'],
    width: 1920,
    height: 1080,
  },
};
