// eslint-disable-next-line no-shadow
export enum AppConfigKeys {
  customerEndSale = 'customer_end_sale',
  promotionalDay = 'promotional_day',
  ancineExibidor = 'ancine_exibidor',
  ancineReportEmail = 'ancine_report_email',
  blingCacheTtl = 'bling_cache_ttl',
  blingCurrentOrderNumber = 'bling_current_order_number',
  blingSendNfce = 'bling_send_nfce',
  blingDefaultCustomerId = 'bling_default_customer_id',
  blingProductsCategoryId = 'bling_products_category_id',
  blingHalfTicketProductId = 'bling_half_ticket_product_id',
  blingFullTicketProductId = 'bling_full_ticket_product_id',
  blingCourtesyTicketProductId = 'bling_courtesy_ticket_product_id',
  blingCashPaymentMethodId = 'bling_cash_payment_method_id',
  blingPixPaymentMethodId = 'bling_pix_payment_method_id',
  blingCreditCardPaymentMethodId = 'bling_credit_card_payment_method_id',
  blingDebitCardPaymentMethodId = 'bling_debit_card_payment_method_id',
}
