import { User, UserRole } from '@cinesj/models';
import { v5 as uuidV5 } from 'uuid';

import { appsUuidNamespaces } from './apps-uuid';

export type IInternalUsers = {
  bot: Omit<User, 'createdAt' | 'updatedAt' | 'avatar'>;
};

export const InternalUsers: IInternalUsers = {
  bot: {
    id: uuidV5('bot', appsUuidNamespaces.api),
    name: 'Cine Bot',
    email: 'bot@transbrotense.com.br',
    role: UserRole.Admin,
    cpf: null,
    isConfirmed: true,
  },
};
