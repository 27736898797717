import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

export type AppShellSectionProps = BoxProps & {
  grow?: boolean;
};

export function AppShellSection({
  grow = false,
  children,
  ...props
}: AppShellSectionProps) {
  return (
    <Box flexGrow={grow ? 1 : 0} {...props}>
      {children}
    </Box>
  );
}
AppShellSection.displayName = '@cinesj/web-ui/components/AppShellSection';
