import React from 'react';

import {
  Table as ChakraTable,
  TableProps as ChakraTableProps,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Table as TanstackTable, flexRender } from '@tanstack/react-table';

export type TableProps = {
  table: TanstackTable<any>;
  showFooter?: boolean;
  tableContainerProps?: TableContainerProps;
  tableProps?: ChakraTableProps;
};

export function Table({
  table,
  showFooter = false,
  tableContainerProps,
  tableProps,
}: TableProps) {
  return (
    <TableContainer w="full" {...tableContainerProps}>
      <ChakraTable colorScheme="black" {...tableProps}>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
        {showFooter && (
          <Tfoot>
            {table.getFooterGroups().map(footerGroup => (
              <Tr key={footerGroup.id}>
                {footerGroup.headers.map(header => (
                  <Td key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tfoot>
        )}
      </ChakraTable>
    </TableContainer>
  );
}
