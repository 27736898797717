import React from 'react';
import {
  MdOutlineAdd,
  MdOutlineFullscreen,
  MdOutlineRemove,
} from 'react-icons/md';

import { Box, Icon, IconButton, Tooltip, VStack } from '@chakra-ui/react';

export type CanvasControlsProps = {
  controlsPosition: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  spacing?: string;
  methods: {
    addScale: () => void;
    subScale: () => void;
    fitView: (imediate?: boolean) => void;
  };
};

export function CanvasControls({
  controlsPosition,
  spacing,
  methods: { addScale, subScale, fitView },
}: CanvasControlsProps) {
  return (
    <Box
      position="absolute"
      zIndex="sticky"
      top={
        controlsPosition === 'top-left' || controlsPosition === 'top-right'
          ? spacing
          : undefined
      }
      bottom={
        controlsPosition === 'bottom-left' ||
        controlsPosition === 'bottom-right'
          ? spacing
          : undefined
      }
      left={
        controlsPosition === 'top-left' || controlsPosition === 'bottom-left'
          ? spacing
          : undefined
      }
      right={
        controlsPosition === 'top-right' || controlsPosition === 'bottom-right'
          ? spacing
          : undefined
      }
    >
      <VStack>
        <Tooltip label="aumentar o zoom">
          <IconButton
            aria-label="aumentar o zoom"
            icon={<Icon as={MdOutlineAdd} />}
            onClick={() => addScale()}
            boxShadow="md"
          />
        </Tooltip>
        <Tooltip label="diminuir o zoom">
          <IconButton
            aria-label="diminuir o zoom"
            icon={<Icon as={MdOutlineRemove} />}
            onClick={() => subScale()}
            boxShadow="md"
          />
        </Tooltip>
        <Tooltip label="Ajustar a visualização">
          <IconButton
            aria-label="Ajustar a visualização"
            icon={<Icon as={MdOutlineFullscreen} />}
            onClick={() => fitView()}
            boxShadow="md"
          />
        </Tooltip>
      </VStack>
    </Box>
  );
}

export default CanvasControls;
