import React, { ForwardedRef, forwardRef } from 'react';
import { Control, useController } from 'react-hook-form';

import {
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useMergeRefs,
} from '@chakra-ui/react';
import { useID } from '@cinesj/web-ui/hooks/use-id';

export type SwitchProps = {
  name: string;
  label?: string;
  control?: Control<any, any>;
} & Omit<ChakraSwitchProps, 'onChange' | 'isChecked'>;

function SwitchBase(
  { id, control, name: propsName, label: propsLabel, ...props }: SwitchProps,
  inputRef: ForwardedRef<HTMLInputElement>,
) {
  const inputId = useID('switch', id);
  const {
    field: { value, onChange, onBlur, ref, name: fieldName },
    fieldState: { error },
  } = useController({
    name: propsName,
    control,
    defaultValue: props.defaultChecked,
  });

  const label = propsLabel || propsName;

  return (
    <FormControl id={inputId} display="flex" alignItems="center">
      <FormLabel mb="0">{label}</FormLabel>
      <ChakraSwitch
        id={inputId}
        ref={useMergeRefs(inputRef, ref)}
        name={fieldName}
        onChange={onChange}
        onBlur={onBlur}
        isChecked={value}
        {...props}
      />

      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
}

export const Switch = forwardRef(SwitchBase);
