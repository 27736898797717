import React from 'react';
import { MdOutlineAdd } from 'react-icons/md';

import { Button, Icon, IconButton } from '@chakra-ui/react';
import { useMediaQuery } from '@cinesj/web-ui/hooks/use-media-query';
import { OmniRouterLink } from '@cinesj/web-ui/omni-router';

export type NewButtonProps = {
  href: string;
  children: string;
};

export function NewButton({ href, children }: NewButtonProps) {
  const mediaQuery = useMediaQuery();

  if (!mediaQuery.sm) {
    return (
      <IconButton
        as={OmniRouterLink}
        href={href}
        icon={<Icon as={MdOutlineAdd} />}
        aria-label={children}
      />
    );
  }

  return (
    <Button
      as={OmniRouterLink}
      href={href}
      leftIcon={<Icon as={MdOutlineAdd} />}
    >
      {children}
    </Button>
  );
}
