import React, { ForwardedRef, forwardRef } from 'react';
import { Control, FieldError, useController } from 'react-hook-form';

import {
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputFieldProps,
  NumberInputStepper,
  useMergeRefs,
} from '@chakra-ui/react';
import { useID } from '@cinesj/web-ui/hooks/use-id';

export type NumberInputProps = {
  name: string;
  label?: string;
  helperText?: string;
  control: Control<any>;
  error?: FieldError | string;
  fieldProps?: NumberInputFieldProps;
} & ChakraNumberInputProps;

function NumberInputBase(
  {
    error: propsError,
    control,
    id,
    label: propsLabel,
    helperText,
    isDisabled,
    isInvalid: propsIsInvalid,
    isReadOnly,
    isRequired,
    fieldProps,
    defaultValue = 0,
    ...props
  }: NumberInputProps,
  inputRef: ForwardedRef<HTMLInputElement>,
) {
  const controller = useController({
    name: props.name,
    control,
    defaultValue,
    rules: {
      required: isRequired,
    },
  });

  const inputId = useID('input', id);

  const label = propsLabel || props.name;

  const isInvalid = !!controller.fieldState.error || propsIsInvalid;

  const error = propsError || controller.fieldState.error;

  const mergedRef = useMergeRefs(inputRef, controller.field.ref);

  const mergedProps = {
    ...fieldProps,
    id: inputId,
    ref: mergedRef,
  };

  return (
    <FormControl
      isDisabled={isDisabled}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isInvalid={isInvalid}
      id={inputId}
    >
      <FormLabel>{label}</FormLabel>
      <ChakraNumberInput
        defaultValue={defaultValue}
        {...props}
        {...controller.field}
      >
        <NumberInputField {...mergedProps} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </ChakraNumberInput>
      {error ? (
        <FormErrorMessage>
          {typeof error === 'string' ? error : error.message}
        </FormErrorMessage>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

/**
 * A number input component. It is a wrapper around the Chakra UI NumberInput component. It also supports the use of react-hook-form.
 * @see Docs https://chakra-ui.com/docs/form/number-input
 */
export const NumberInput = forwardRef(NumberInputBase);
