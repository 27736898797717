import React, { ForwardedRef, forwardRef } from 'react';
import { Control, useController } from 'react-hook-form';

import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  useMergeRefs,
} from '@chakra-ui/react';
import { useID } from '@cinesj/web-ui/hooks/use-id';

export type SelectOptions = {
  label?: string;
  value: string;
};

export type SelectProps = {
  name: string;
  label?: string;
  control?: Control<any, any>;
  options: SelectOptions[];
  helperText?: string;
} & Omit<ChakraSelectProps, 'onChange' | 'value'>;

function SelectBase(
  {
    id,
    control,
    name: propsName,
    label: propsLabel,
    options,
    placeholder = 'Selecione...',
    helperText,
    isReadOnly,
    isRequired,
    isDisabled,
    isInvalid: propsIsInvalid,
    ...props
  }: SelectProps,
  inputRef: ForwardedRef<HTMLInputElement>,
) {
  const inputId = useID('select', id);
  const {
    field: { value, onChange, onBlur, ref, name: fieldName },
    fieldState: { error },
  } = useController({
    name: propsName,
    control,
    defaultValue: props.defaultValue || options[0]?.value,
  });

  const label = propsLabel || propsName;

  const isInvalid = !!error || !!propsIsInvalid;

  const mergedRef = useMergeRefs(inputRef, ref);

  return (
    <FormControl
      id={inputId}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
    >
      <FormLabel>{label}</FormLabel>
      <ChakraSelect
        ref={mergedRef}
        name={fieldName}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      >
        {options.map(({ label: optionLabel, value: optionValue }) => (
          <option key={optionValue} value={optionValue}>
            {optionLabel || optionValue}
          </option>
        ))}
      </ChakraSelect>
      {error ? (
        <FormErrorMessage>{error.message}</FormErrorMessage>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export const Select = forwardRef(SelectBase);
