import { GenIcon, IconTree } from 'react-icons';

const IconData: IconTree = {
  tag: 'svg',
  attr: { version: '1.1', viewBox: '0 0 6.35 6.35', stroke: 'currentColor' },
  child: [
    {
      tag: 'path',
      attr: { fill: 'none', strokeWidth: '0', d: 'M0 0h6.35v6.35H0z' },
      child: [],
    },
    {
      tag: 'path',
      attr: {
        strokeWidth: '0',
        d: 'M2.224 1.23a.372.372 0 00-.373.373v1.923c0 .206.167.373.373.373h1.924a.372.372 0 00.372-.373V1.603a.372.372 0 00-.372-.373zM.699 1.7a.171.171 0 00-.17.17c0 1.027-.002 2.056 0 3.08a.17.17 0 00.171.171h4.95a.17.17 0 00.171-.17v-3.08a.17.17 0 00-.17-.171h-.452a.171.171 0 00-.17.17v2.287a.17.17 0 01-.171.17H1.493a.17.17 0 01-.171-.17V1.87a.17.17 0 00-.17-.17H.699z',
      },
      child: [],
    },
  ],
};

export const CjArmchairTopView = GenIcon(IconData);
