import React from 'react';

import { HStack } from '@chakra-ui/react';
import { CjArmchairTopView } from '@cinesj/icons';

import { Canvas, CanvasItem } from '../canvas';
import type { CanvasDimensions } from '../canvas/types';
import type { Sector } from './types';

export type RoomCanvasProps = {
  dimensions: CanvasDimensions;
  sectors: Sector[];
  onSeatSelect?: (seatId: string, isSelected: boolean) => void;
  seatsSelected?: string[];
  seatsDisabled?: string[];
};

export function RoomCanvas({
  dimensions,
  sectors,
  onSeatSelect,
  seatsSelected = [],
  seatsDisabled = [],
}: RoomCanvasProps) {
  return (
    <Canvas dimensions={dimensions}>
      {sectors.map(sector =>
        sector.rows.map(row => (
          <CanvasItem
            key={row.id}
            initialPosition={{
              x: row.cords.x,
              y: row.cords.y,
            }}
          >
            <HStack>
              {row.seats
                .sort((a, b) => a.index - b.index)
                .map(seat => {
                  const isSelected = seatsSelected.includes(seat.id);
                  const isDisabled = seatsDisabled.includes(seat.id);

                  const onSelectMethod = () => {
                    if (isDisabled) {
                      return;
                    }

                    onSeatSelect?.(seat.id, isSelected);
                  };

                  const getColor = (
                    sectorColor: string,
                    /* eslint-disable no-shadow */
                    isSelected: boolean,
                    isDisabled: boolean,
                    /* eslint-enable no-shadow */
                  ) => {
                    if (isDisabled) {
                      return '#718096';
                    }

                    if (isSelected) {
                      return '#CBD5E0';
                    }

                    return sectorColor;
                  };

                  return (
                    <button
                      key={seat.id}
                      type="button"
                      aria-label={`Poltrona ${seat.number}`}
                      onClick={onSelectMethod}
                      disabled={isDisabled}
                    >
                      <CjArmchairTopView
                        style={{
                          width: '4rem',
                          height: '4rem',
                          color: getColor(sector.color, isSelected, isDisabled),
                        }}
                      />
                    </button>
                  );
                })}
            </HStack>
          </CanvasItem>
        )),
      )}
    </Canvas>
  );
}

export default RoomCanvas;
