import React, {
  ForwardedRef,
  ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import { animated, useSpring } from 'react-spring';

export type CanvasItemProps = {
  children: ReactNode;
  initialPosition?: {
    x: number;
    y: number;
  };
  initialScale?: number;
};

export type CanvasItemHandles = {
  setPosition: (x: number, y: number, imediate?: false) => void;
  setScale: (scale: number, imediate?: false) => void;
  getCurrentPosition: () => { x: number; y: number };
  getCurrentScale: () => number;
};

function CanvasItemBase(
  { children, initialPosition, initialScale }: CanvasItemProps,
  canvasItemRef: ForwardedRef<CanvasItemHandles>,
) {
  const [styles, api] = useSpring(() => ({
    x: initialPosition?.x ?? 0,
    y: initialPosition?.y ?? 0,
    scale: initialScale ?? 1,
  }));

  const setPositionHandler = useCallback<CanvasItemHandles['setPosition']>(
    (x, y, imediate) => {
      api.start({ x, y, immediate: imediate });
    },
    [api],
  );

  const setScaleHandler = useCallback<CanvasItemHandles['setScale']>(
    (scale, imediate) => {
      api.start({ scale, immediate: imediate });
    },
    [api],
  );

  const getCurrentPositionHandler = useCallback<
    CanvasItemHandles['getCurrentPosition']
  >(
    () => ({
      x: styles.x.get(),
      y: styles.y.get(),
    }),
    [styles],
  );

  const getCurrentScaleHandler = useCallback<
    CanvasItemHandles['getCurrentScale']
  >(() => styles.scale.get(), [styles]);

  useImperativeHandle(canvasItemRef, () => ({
    setPosition: setPositionHandler,
    setScale: setScaleHandler,
    getCurrentPosition: getCurrentPositionHandler,
    getCurrentScale: getCurrentScaleHandler,
  }));

  return (
    <animated.div
      style={{
        position: 'absolute',
        x: styles.x,
        y: styles.y,
        scale: styles.scale,
      }}
    >
      {children}
    </animated.div>
  );
}

export const CanvasItem = forwardRef(CanvasItemBase);

export default CanvasItem;
