import React, { JSXElementConstructor, ReactElement } from 'react';
import { MdOutlineGridView } from 'react-icons/md';

import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { DisplayColumnDef } from '@tanstack/react-table';

import { ButtonAction, IButtonAction, ILinkAction, LinkAction } from './action';

export type CreateActionProps<T extends object> = {
  actions: Array<IButtonAction<T> | ILinkAction<T>>;
  header?: string;
  menuButton?: {
    value?: string;
    icon?: ReactElement<any, string | JSXElementConstructor<any>>;
  };
};

export function createAction<T extends object>({
  header = 'Ações',
  menuButton,
  actions,
}: CreateActionProps<T>): DisplayColumnDef<T> {
  return {
    id: `actions-${header}`,
    header,
    cell: ({ row }) => (
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={
            <Icon
              as={((menuButton && menuButton.icon) || MdOutlineGridView) as any}
            />
          }
        >
          {(menuButton && menuButton.value) || 'Ações'}
        </MenuButton>
        <MenuList>
          {actions.map(action => {
            const isActive =
              typeof action.isActive === 'function'
                ? action.isActive(row)
                : action.isActive ?? true;

            if (!isActive) {
              return null;
            }

            if (action.type === 'button') {
              return (
                <ButtonAction key={action.label} action={action} row={row} />
              );
            }
            if (action.type === 'link') {
              return (
                <LinkAction key={action.label} action={action} row={row} />
              );
            }
            return null;
          })}
        </MenuList>
      </Menu>
    ),
  };
}

export default createAction;
