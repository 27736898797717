import { useCallback, useReducer } from 'react';

import { AsyncMutationAction, AsyncMutationReducer } from './reducer';

export type UseAsyncMutationResult<TData, TError, TVariables> = {
  data: TData | undefined;
  error: TError | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  mutate: (variables: TVariables) => Promise<TData | undefined>;
};

export type UseAsyncMutationOptions<TData, TError> = {
  onSuccess?: (data: TData) => void;
  onError?: (error: TError) => void;
};

export function useAsyncMutation<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
>(
  asyncFn: (variables: TVariables) => Promise<TData>,
  option?: UseAsyncMutationOptions<TData, TError>,
): UseAsyncMutationResult<TData, TError, TVariables> {
  const [state, dispatch] = useReducer(
    (prevState: any, action: AsyncMutationAction<TData, TError>) =>
      AsyncMutationReducer<TData, TError>(prevState, action),
    {
      data: undefined as TData | undefined,
      error: undefined as TError | undefined,
      isLoading: false,
      isSuccess: false,
      isError: false,
    },
  );

  const mutate = useCallback(
    async (variables: TVariables): Promise<TData | undefined> => {
      dispatch({
        type: 'mutation-start',
      });
      try {
        const newData = await asyncFn(variables);
        dispatch({
          type: 'mutation-success',
          payload: newData,
        });
        if (option?.onSuccess) {
          option.onSuccess(newData);
        }
        return newData;
      } catch (err: any) {
        dispatch({
          type: 'mutation-error',
          payload: err,
        });

        if (option?.onError) {
          option.onError(err);
        }

        return undefined;
      }
    },
    [asyncFn],
  );

  return {
    data: state.data,
    error: state.error,
    isError: state.isError,
    isLoading: state.isLoading,
    isSuccess: state.isSuccess,
    mutate,
  };
}
