import React from 'react';

import { BoxProps, Flex } from '@chakra-ui/react';

import { constants } from './constants';

export function AppShellSidebar({ children, ...props }: BoxProps) {
  return (
    <Flex
      as="aside"
      flexDirection="column"
      position="fixed"
      width={`${constants.sidebarWidth}px`}
      height="100dvh"
      borderRight="1px solid"
      borderRightColor="gray.300"
      {...props}
    >
      {children}
    </Flex>
  );
}
AppShellSidebar.displayName = '@cinesj/web-ui/components/AppShellSidebar';
