import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import { constants } from './constants';
import { useAppShell } from './context';

export function AppShellMain({ children, ...props }: BoxProps) {
  const appShell = useAppShell();
  return (
    <Box as="main" ml={constants.sidebarWidth} p={2} minH="100dvh" {...props}>
      {children}
      {appShell.showMainSpace && <Box h="25vh" />}
    </Box>
  );
}
AppShellMain.displayName = '@cinesj/web-ui/components/AppShellMain';
