/* eslint-disable no-await-in-loop, no-plusplus */

/**
 * Allow to use async functions in forEach
 * @param array array that will be traveled
 * @param cb callback that will be called for each item in the array
 */
export async function asyncForEach<T>(
  array: T[],
  cb: (value: T, index?: number) => Promise<void>,
) {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    await cb(element, index);
  }
}
