import { useEffect } from 'react';

import { useAppShell } from '@cinesj/web-ui/components/app-shell/context';

export function useDisableAppShellMainSpace() {
  const appShell = useAppShell();

  useEffect(() => {
    appShell.setShowMainSpace(false);
    return () => appShell.setShowMainSpace(true);
  }, []);
}
