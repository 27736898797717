export type TruncateTextOptions = {
  length: number;
  suffix?: string;
  preserve?: boolean;
};

export function truncateText(
  text: string,
  options: TruncateTextOptions,
): string {
  if (text.length <= options.length) {
    return text;
  }

  const { length, suffix = '...', preserve } = options;
  const end = preserve ? text.slice(0, length) : text.slice(0, length).trim();
  return end + suffix;
}
