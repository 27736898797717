// eslint-disable-next-line no-shadow
export enum BlingNotaStatus {
  pendente = 1,
  cancelada = 2,
  aguardandoRecibo = 3,
  rejeitada = 4,
  autorizada = 5,
  emitidaDANFE = 6,
  registrada = 7,
  aguardandoProtocolo = 8,
  denegada = 9,
  consultaSituacao = 10,
  bloqueada = 11,
}
