import React, { Suspense, lazy } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { cinesjTheme } from '@cinesj/web-ui/styles/themes';

const ModalProvider = lazy(() => import('@cinesj/web-ui/components/modal'));

export type CinesjProviderProps = {
  theme?: typeof cinesjTheme;
  replaceOrMergeTheme?: 'replace' | 'merge';
  children: React.ReactNode;
};

export function CinesjProvider({
  children,
  theme: propsTheme,
  replaceOrMergeTheme = 'merge',
}: CinesjProviderProps) {
  const theme =
    replaceOrMergeTheme === 'replace'
      ? propsTheme
      : { ...cinesjTheme, ...propsTheme };

  return (
    <ChakraProvider resetCSS theme={theme}>
      <Suspense fallback={null}>
        <ModalProvider>{children}</ModalProvider>
      </Suspense>
    </ChakraProvider>
  );
}
