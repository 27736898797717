import React from 'react';

import { Card, CardBody, CardProps } from '@chakra-ui/react';

export function PageShellSection({
  children,
  w = 'full',
  variant = 'outline',
  ...props
}: CardProps) {
  return (
    <Card w={w} variant={variant} {...props}>
      <CardBody>{children}</CardBody>
    </Card>
  );
}
