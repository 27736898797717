import React, { useCallback, useMemo, useReducer } from 'react';

import { randomId } from '@cinesj/utils';

import { ConfirmModal } from './confirm-modal';
import { ContentModal } from './content-modal';
import { ModalContext } from './context';
import { Modal } from './modal';
import { modalReducer } from './reducer';
import { ModalContextProps, ModalProviderProps } from './types';

export function ModalProvider({ children }: ModalProviderProps) {
  const [state, dispatch] = useReducer(modalReducer, {
    modals: [],
    current: null,
  });

  const closeAll = useCallback<ModalContextProps['closeAll']>(
    canceled => {
      state.modals.forEach(modal => {
        if (modal.type === 'confirm' && canceled) {
          modal.props.onCancel?.();
        }
        modal.props?.onClose?.();
      });
      dispatch({ type: 'closeAll' });
    },
    [state.modals],
  );

  const close = useCallback<ModalContextProps['close']>(
    (id, canceled) => {
      if (state.modals.length <= 1) {
        closeAll(canceled);
      } else {
        const modal = state.modals.find(fndModal => fndModal.id === id);
        modal?.props?.onClose?.();
        dispatch({ type: 'close', payload: id });
      }
    },
    [state.modals],
  );

  const open = useCallback<ModalContextProps['open']>(props => {
    const id = props.id || randomId();

    dispatch({
      type: 'open',
      payload: {
        id,
        type: 'content',
        props,
      },
    });

    return id;
  }, []);

  const openConfirm = useCallback<ModalContextProps['openConfirm']>(props => {
    const id = props.id || randomId();

    dispatch({
      type: 'open',
      payload: {
        id,
        type: 'confirm',
        props,
      },
    });

    return id;
  }, []);

  const getCurrentModal = () => {
    switch (state.current?.type) {
      case 'content': {
        const {
          children: currentModalChildren,
          innerProps,
          title,
          noCloseButton,
          footer,
          ...rest
        } = state.current.props;

        return {
          modalProps: {
            innerProps,
            ...rest,
          },
          content: (
            <ContentModal
              title={title}
              noCloseButton={noCloseButton}
              innerProps={innerProps}
              footer={footer}
            >
              {currentModalChildren}
            </ContentModal>
          ),
        };
      }
      case 'confirm': {
        const {
          children: currentModalChildren,
          title,
          label,
          onConfirm,
          onCancel,
          closeOnCancel,
          closeOnConfirm,
          innerProps,
          ...rest
        } = state.current.props;

        return {
          modalProps: {
            innerProps,
            ...rest,
          },
          content: (
            <ConfirmModal
              title={title}
              innerProps={innerProps}
              label={label}
              onConfirm={onConfirm}
              onCancel={onCancel}
              closeOnCancel={closeOnCancel}
              closeOnConfirm={closeOnConfirm}
            >
              {currentModalChildren}
            </ConfirmModal>
          ),
        };
      }
      default: {
        return {
          modalProps: {},
          content: null,
        };
      }
    }
  };

  const { modalProps: currentModalProps, content } = getCurrentModal();

  const contextValue = useMemo(
    () => ({
      modals: state.modals,
      close,
      closeAll,
      open,
      openConfirm,
    }),
    [state.modals, close, closeAll, open, openConfirm],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      <Modal
        {...currentModalProps}
        isOpen={state.modals.length > 0}
        onClose={() => close(state.current?.id as string)}
      >
        {content}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
}
export default ModalProvider;
