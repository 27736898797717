import { v5 as uuid } from 'uuid';

import { appsNames } from './apps';

export const baseDomainUuid = '9f8c8611-063e-576d-a3f5-6c9a726053f5'; // uuid v5 of "cinesaojose.com.br"

export const appsUuidNamespaces = {
  api: uuid(appsNames.api, baseDomainUuid), // 05b8905d-21b4-5f6a-8a56-820b2ff47d3c
  queue: uuid(appsNames.queue, baseDomainUuid), // b3d18901-ac5f-57e8-b0bb-8e631bbe0aa4
  admin: uuid(appsNames.admin, baseDomainUuid), // 86b04054-dad6-5249-9bd0-375ebc5389b1
  web: uuid(appsNames.web, baseDomainUuid), // d05ace2c-7c09-5f7b-bd88-3f65ec648446
};
