import { useState } from 'react';

import { useEventListener } from '@cinesj/hooks/use-event-listener';

export function useOffline(defaultValue = false): boolean {
  const [isOffline, setIsOffline] = useState(defaultValue);

  useEventListener('offline', () => {
    setIsOffline(true);
  });
  useEventListener('online', () => {
    setIsOffline(false);
  });

  return isOffline;
}
