export const colors = {
  brand: {
    900: '#00150f',
    800: '#002a1c',
    700: '#003e2a',
    600: '#005338',
    500: '#006746',
    400: '#009062',
    300: '#00a470',
    200: '#00b97d',
    100: '#00cd8b',
  },
};
