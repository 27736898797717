import React, { ForwardedRef, forwardRef } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useBoolean,
  useMergeRefs,
} from '@chakra-ui/react';
import { useID } from '@cinesj/web-ui/hooks/use-id';

export type PasswordProps = {
  label?: string;
  helperText?: string;
  register?: UseFormRegisterReturn;
  error?: FieldError | string;
} & Omit<InputProps, 'type'>;

function PasswordBase(
  {
    register,
    error,
    id,
    label: propsLabel,
    helperText,
    isRequired,
    isReadOnly,
    isDisabled,
    ...props
  }: PasswordProps,
  inputRef: ForwardedRef<HTMLInputElement>,
) {
  const inputId = useID('password', id);

  const [show, visibility] = useBoolean();

  const label = propsLabel || register?.name || props.name;

  const isInvalid = !!error || props.isInvalid;

  const mergedRef = useMergeRefs(inputRef, register?.ref);

  const mergedProps = {
    id: inputId,
    ref: mergedRef,
    ...props,
    ...register,
    type: show ? 'text' : 'password',
  };

  return (
    <FormControl
      isDisabled={isDisabled}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isInvalid={isInvalid}
      id={inputId}
    >
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Input {...mergedProps} />
        <InputRightElement>
          <IconButton
            icon={
              <Icon as={show ? MdOutlineVisibilityOff : MdOutlineVisibility} />
            }
            onClick={visibility.toggle}
            aria-label={show ? 'esconde a senha' : 'mostra a senha'}
            variant="ghost"
          />
        </InputRightElement>
      </InputGroup>
      {error ? (
        <FormErrorMessage>
          {typeof error === 'string' ? error : error.message}
        </FormErrorMessage>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export const Password = forwardRef(PasswordBase);
