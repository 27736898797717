import React, { ReactNode, useMemo, useState } from 'react';

import { AppShellContext } from './context';

export type AppShellProviderProps = {
  children: ReactNode;
};

export function AppShellProvider({ children }: AppShellProviderProps) {
  const [showMainSpace, setShowMainSpace] = useState(true);

  const valuesMemo = useMemo(() => {
    return {
      showMainSpace,
      setShowMainSpace,
    };
  }, [showMainSpace, setShowMainSpace]);

  return (
    <AppShellContext.Provider value={valuesMemo}>
      {children}
    </AppShellContext.Provider>
  );
}
AppShellProvider.displayName = '@cinesj/web-ui/components/AppShellProvider';
