import type { ComponentType } from 'react';

import { OmniRouterLinkProps } from './link';

type ComponentPropsWithRef<R, P> = P & { ref?: R };

export type IOmniRouter = {
  Link: ComponentType<ComponentPropsWithRef<any, OmniRouterLinkProps>>;
  useRouter: () => {
    back(): void;
    forward(): void;
    refresh(): void;
    push(href: string): void;
    replace(href: string): void;
    prefetch(href: string): void;
  };
  useSearchParams: () => URLSearchParams;
};

export const OmniRouter = {} as IOmniRouter;
