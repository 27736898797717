import React, { ForwardedRef, forwardRef } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  useMergeRefs,
} from '@chakra-ui/react';
import { useID } from '@cinesj/web-ui/hooks/use-id';

export type TextareaProps = {
  label?: string;
  helperText?: string;
  register?: UseFormRegisterReturn;
  error?: FieldError | string;
} & ChakraTextareaProps;

function TextareaBase(
  {
    register,
    error,
    label: propsLabel,
    id: propsId,
    isDisabled,
    isInvalid: propsIsInvalid,
    helperText,
    isReadOnly,
    isRequired,
    ...props
  }: TextareaProps,
  inputRef: ForwardedRef<HTMLTextAreaElement>,
) {
  const id = useID('textarea', propsId);
  const label = propsLabel || register?.name || props.name;

  const mergedRef = useMergeRefs(inputRef, register?.ref);

  const mergedProps = {
    ...props,
    ...register,
  };

  const isInvalid = !!error || propsIsInvalid;

  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      id={id}
    >
      <FormLabel>{label}</FormLabel>
      <ChakraTextarea {...mergedProps} ref={mergedRef} />
      {isInvalid ? (
        <FormErrorMessage>
          {typeof error === 'string' ? error : error?.message}
        </FormErrorMessage>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export const Textarea = forwardRef(TextareaBase);
