import { SessionAudio } from '@cinesj/models';
import { Transform } from 'class-transformer';

export interface Session {
  id: string;

  eventId: string;

  audio: SessionAudio;

  subtitled: boolean;

  startAt: Date;

  endAt: Date;

  roomId: string;

  layoutId: string;

  createdAt: Date;

  updatedAt: Date;
}

export class SessionSchema implements Session {
  id: string;

  eventId: string;

  audio: SessionAudio;

  subtitled: boolean;

  @Transform(({ value }) => new Date(value))
  startAt: Date;

  @Transform(({ value }) => new Date(value))
  endAt: Date;

  roomId: string;

  layoutId: string;

  @Transform(({ value }) => new Date(value))
  createdAt: Date;

  @Transform(({ value }) => new Date(value))
  updatedAt: Date;
}
