import { useState, useEffect } from 'react';

export type UseScreenSizeReturn = {
  width: number;
  height: number;
};

export function useScreenSize(): UseScreenSizeReturn {
  const [screenSize, setScreenSize] = useState<UseScreenSizeReturn>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
}
