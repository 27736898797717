import React from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';

import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import type { Paginator } from '@cinesj/web-ui/hooks/use-paginator';

export type PaginatorProps = {
  paginator: Paginator;
};

export function PaginatorController({ paginator }: PaginatorProps) {
  const showFistPageDots =
    paginator.currentPage > paginator.siblingPagesCount + 2;

  const showLastPageDots =
    paginator.currentPage + 1 + paginator.siblingPagesCount <
    paginator.lastPage;

  return (
    <Flex w="full" justifyContent="end">
      <ButtonGroup
        size="sm"
        fontSize="xs"
        alignItems="center"
        flexWrap="wrap"
        gap={2}
        spacing={0}
      >
        {paginator.currentPage > 1 && (
          <Tooltip label="Pagina anterior">
            <IconButton
              aria-label="pagina anterior"
              icon={<Icon as={MdArrowBack} />}
              onClick={paginator.previousPage}
            />
          </Tooltip>
        )}
        {paginator.currentPage > paginator.siblingPagesCount + 1 && (
          <>
            <Tooltip label="Primeira página">
              <Button
                variant={showFistPageDots ? 'solid' : 'outline'}
                onClick={() => paginator.setCurrentPage(1)}
              >
                1
              </Button>
            </Tooltip>
            {showFistPageDots && (
              <Text color="gray.500" w="8" textAlign="center">
                ...
              </Text>
            )}
          </>
        )}
        {paginator.pages.map(page => (
          <Tooltip key={page} label={`Página ${page}`}>
            <Button
              variant={page === paginator.currentPage ? 'solid' : 'outline'}
              onClick={() => paginator.setCurrentPage(page)}
            >
              {page}
            </Button>
          </Tooltip>
        ))}
        {Number.isFinite(paginator.lastPage) &&
          paginator.currentPage + paginator.siblingPagesCount <
            paginator.lastPage && (
            <>
              {showLastPageDots && (
                <Text color="gray.500" w="8" textAlign="center">
                  ...
                </Text>
              )}
              <Tooltip label="Ultima pagina">
                <Button
                  variant={showLastPageDots ? 'solid' : 'outline'}
                  onClick={() => paginator.setCurrentPage(paginator.lastPage)}
                >
                  {paginator.lastPage}
                </Button>
              </Tooltip>
            </>
          )}
        {paginator.currentPage < paginator.lastPage && (
          <Tooltip label="proxima pagina">
            <IconButton
              aria-label="proxima pagina"
              icon={<Icon as={MdArrowForward} />}
              onClick={paginator.nextPage}
            />
          </Tooltip>
        )}
      </ButtonGroup>
    </Flex>
  );
}

export default PaginatorController;
