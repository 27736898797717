import { useCallback, useEffect, useState } from 'react';

export type UseControlledStateProps<T> = {
  value: T;
  onChange?: (value?: T) => void;
};

export function useControlledState<T>(
  props: UseControlledStateProps<T>,
): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(props.value);

  const setValueMethod = useCallback(
    (newValue: T) => {
      setValue(newValue);
      if (props.onChange) {
        props.onChange(newValue);
      }
    },
    [props.onChange],
  );

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value);
    }
  }, [props.value]);

  return [value, setValueMethod];
}
