import { UrlObject } from 'url';
import React from 'react';

import { MenuItem } from '@chakra-ui/react';
import { OmniRouterLink } from '@cinesj/web-ui/omni-router';
import { getUrlPathname } from '@cinesj/web-ui/utils/get-url-pathname';
import { parseUrlQueryParams } from '@cinesj/web-ui/utils/parse-url-query-params';
import type { Row } from '@tanstack/react-table';

import type { ILinkAction } from './type';

export type LinkActionProps<T extends object = any> = {
  action: ILinkAction<T>;
  row: Row<T>;
};

export function LinkAction({ action, row }: LinkActionProps) {
  const href =
    typeof action.href === 'function' ? action.href(row) : action.href;

  const returnUrl =
    typeof action.returnUrl === 'function'
      ? action.returnUrl(row)
      : action.returnUrl;

  const url: UrlObject = {
    ...(typeof href === 'string'
      ? {
          pathname: getUrlPathname(href),
        }
      : href),
    query: typeof href === 'string' ? parseUrlQueryParams(href) : href.query,
  };

  if (returnUrl) {
    url.query = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...url.query,
      returnUrl,
    };
  }

  return (
    <MenuItem as={OmniRouterLink} href={url as any} icon={action.icon}>
      {action.label}
    </MenuItem>
  );
}

export default LinkAction;
